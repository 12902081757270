"use strict";
import Swiper, {
  Autoplay,
  Pagination
} from "swiper";

window.addEventListener("DOMContentLoaded", () => {
  const swiper = new Swiper(".swiper", {
    modules: [Autoplay, Pagination],
    loop: true,
    speed: 700,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    autoplay: {
      delay: 4500,
    },
  });
});
